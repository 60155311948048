@import 'src/styles/mixins';
@import 'src/styles/variables';

.sidebar {
  position: fixed;
  top: 70px;
  right: 0;
  width: 100%;
  background-color: $white;
  height: 100%;
  z-index: 5;
  padding: 20px 20px 150px 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.fullView {
  // top: 0;
}

.party {
  font-weight: 700;
  color: $black;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  margin: 16px 0;
  cursor: pointer;
  gap: 8px;
}
